.chat-message {
    font-weight: 400;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
}
.chat-message.right {
    float: right;
}
.chat-message .message {
    padding: 10px;
    line-height: 1.3;
    position: relative;
    display: table;
}
.chat-message.right .message {
    background-color: #C00A27;
    color: #FFF;
    float: right;
}
.chat-message.left {
    float: left;
}
.chat-message.left .message {
    background-color: #f2f2f2;
    color: #2D3544;
}
.chat-message .name {
    margin-right: 5px;
}
.chat-message .time {
    display: block;
    margin-top: 4px;
    opacity: .6;
    font-size: .8em;
}
.chat-message .message::before {
    content: "";
    position: absolute;
    bottom: -7px;
}
.chat-message.right .message::before {
    border: 10px solid #C00A27;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    right: 0;
}
.chat-message.left .message::before {
    border: 10px solid #f2f2f2;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    left: 0;
}