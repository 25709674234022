#app-login {
    width: 100%;
    height: 100vh;
    display: table;
    position: relative;
}
#app-login-container {
    display: table-cell;
    vertical-align: middle;
}
#app-login .login {
    width: 50%;
    margin: auto;
}