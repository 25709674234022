.list-categories {
    position: relative;
    text-align: center;
}
.list-categories .list-inline-item {
    width: 17%;
    height: 120px;
    background: #ba1c24;
    color: #FFF;
    margin: 0 20px 20px 0;
    overflow: hidden;
    padding: 10px;
}
.list-categories a {
    color: #FFF;
}
.list-categories img {width: 50%;}
.list-categories span {
    display: block;
    font-weight: bold;
    line-height: 1;
    margin-top: 5px;
}
