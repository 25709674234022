#header {
    background-color: #282f32;
    color: #FFF;
    height: 60px;
    line-height: 44px;
}
.nav-link {
    color: #bbbbbb;
    font-size: 13px;
    font-weight: bold;
}
.nav-link:hover {color: #bbbbbb;}
.nav-link img {height: 25px;}
.nav-home { background-color: #9f1c24; }
.nav-messages { background-color: #8a1c24; }
.nav-search { background-color: #7c1c24;}
.nav-text {
    min-width: 115px;
    text-align: center;
    border-right: 1px solid #3e4b53;
}
.nav-text:last-child {
    border-right: none;
}
.nav-text span.nbr {
    background-color: #8a1c24;
    color: #e8d2d3;
    margin-left: 5px;
    padding: 1px 6px;
    border-radius: 50%;
}
.bg-sur-place {
    cursor: pointer;
    background-color: #475258 !important;
}
.bg-a-emporter {
    cursor: pointer;
    background-color: #047c5e !important;
}
.bg-livraison-a-domicile {
    cursor: pointer;
    background-color: #058881 !important;
}