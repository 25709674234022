
.pop-fieldset {
    font-size: 13px;
}
.pop-fieldset legend {
    font-size: 15px;
    color: #282f32;
    font-weight: bold;
    margin-bottom: 20px;
    border-left: 4px solid #9f1c24;
    padding-left: 5px;
}
.h50px {
    height: 50px !important;
}

#root .form-control.btn-success {
	background-color: #1e7e34;
}
#root .form-control.btn-secondary {
	background-color: #6c757d;
}