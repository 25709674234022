@import "./assets/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');

body {
    font-family: 'Montserrat', sans-serif;
}

a:hover {
    text-decoration: none;
}

.btn:focus {
    box-shadow: none;
}

.white {
    color: #FFF;
}

.h-100p {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

#main {
    margin-left: 350px;
    position: relative;
    /* overflow: hidden; */
    height: 100vh;
}

#content {
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
}

.pop-modal {
    position: absolute;
    top: 0;
    background: #f7f7f7;
    width: 100%;
    height: 100%;
    left: 0;
    padding: 20px;
    z-index: 99999;
}

.w50p {
    width: 50% !important;
}

.list-btns .btn-app,
.list-btns .btn-app-1 {
    display: inline-block;
    width: 17%;
    height: 120px;
    min-height: 50px;
    background: #2778cc;
    color: #FFF;
    margin: 0 10px 10px 0;
    overflow: hidden;
    padding: 5px 0;
    width: 120px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, .2);
}

.list-btns.cat-products .btn-app {
    width: 160px;
    height: 160px;
}

.list-btns .btn-app:last-child {
    margin-right: 0;
}

.list-btns.list-btns-align .btn-app:first-child {
    margin-left: 0;
}

.list-btns .btn-app.hx2 {
    height: 160px;
    max-width: 100%;
    width: 220px;
}

.list-btns .btn-app-1 {
    height: 100%;
    width: auto;
    padding: 0;
}

.list-btns .btn-app-1 .btn {
    border-radius: 0px;
    border: none;
    background-color: #e8b644;
    font-size: 13px;
    text-transform: uppercase;
    height: 70px;
}

.list-btns .btn-app-1 .btn-app {
    margin-bottom: 0px;
}

.list-btns .btn-app .btn-title {
    font-weight: bold;
    padding: 5px;
    font-size: 15px;
    /*border-top: 1px solid rgba(255, 255, 255, 0.3);*/
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 30%;
    margin: 0;
    line-height: 16px;
}

.list-btns .btn-app .img-container {
    height: 60%;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 40%;
    text-align: center;
    justify-content: center;
    font-size: 11px;
    font-weight: bold;
    color: rgba(255, 255, 255, .75);
    padding: 5px;
}

.list-btns .btn-app .item-price {
    width: auto;
    height: 50px;
    float: right;
    background-color: rgba(0, 0, 0, .15);
    border-radius: 50% 0 0 0;
    font-size: 16px;
    padding: 0 12px;
}

.list-btns .btn-app-auto {
    background: transparent;
    box-shadow: none;
    height: 80px;
    vertical-align: top;
}

.list-btns .btn-bordered {
    color: #263444;
}

.list-btns .btn-bordered.active {
    color: #fff;
    background-color: #263444;
    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, .2);
}

.list-btns .btn-bordered.active .btn-item {
    border: none;
}

.btn-bordered .btn-item {
    border: 2px dashed;
    height: 100%;
}

.list-btns .btn-app-auto .img-container,
.list-btns .btn-app-auto .btn-title {
    height: 100%;
}

.list-btns .btn-app .img-container img {
    height: 45px;
}

.list-btns .btn-app .img-container .icon-next,
.icon-arrow-right {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.btn-app-items {
    margin-left: 40px;
}

.btn-app-items .btn-app {
    width: 80px;
    height: 80px;
    float: left;
    margin: 0 10px 0 0;
    padding: 0;
}

.list-btns .btn-app.disabled {
    background-color: #b7b7b7;
    pointer-events: none;
}

.list-btns .btn-app.disabled .img-container {
    color: #dddddd;
}

.bg-yellow {
    background-color: #e8b644 !important;
}

.bg-red {
    background-color: #942a2a !important;
}

.btn-app.w50p {
    width: 48%;
    max-width: 48%;
}

.form-group label {
    font-weight: bold;
    color: #263444;
    font-size: 15px;
}

.form-control {
    border-radius: 0px;
    height: 50px;
    font-size: 20px;
    background: #f7f7f7;
}

.form-control:focus {
    box-shadow: none;
    border-color: #ced4da;
}

.bg-dark {
    background-color: #263444 !important;
}

.btn.bg-dark {
    border: none;
}

.btn-group .btn {
    height: 50px;
    border-radius: 0px;
}

.btn-group-etat {
    width: 100%;
}

.btn-group-etat .btn {
    width: 25%;
    background-color: #f7f7f7;
    border-color: #ced4da;
}

.btn-group-etat.btn-group-auto {
    width: auto;
}

.btn-group-etat.btn-group-auto .btn {
    min-width: 100px;
}

/* .pop-details {
    position: relative;
} */
.pop-header {
    margin-bottom: 0;
}

.closeBtn {
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    background-color: #9f1c24;
    color: #e2bbbe;
    cursor: pointer;
    font-size: 25px;
}

.custom-fieldset legend {
    color: #bbbbbb;
    font-weight: bold;
    border-bottom: 1px solid;
    font-size: 20px;
    margin-bottom: 20px;
}

.toast-notification {
    width: auto !important;
    left: 340px !important;
    right: 150px !important;
    bottom: 0;
    top: auto !important;
    text-align: right !important;
}

.toast-notification span {
    border-radius: 0px !important;
    width: 100% !important;
    text-align: center;
    padding: 20px !important;
    font-weight: 600;
    background-color: #27cc8f !important;
}

.w90percent {
    width: 90%;
    display: inline-block;
}

/*Etat command sidebar*/
#commandEtat {
    position: absolute;
    z-index: 1;
    background-color: #263444;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 10px;
}

#commandEtatContent .btn {
    margin: 10px 0 0;
    text-align: center;
    background-color: #495057;
}

#commandEtatContent h4 {
    text-align: center;
    margin: 20px 0 0;
    font-size: 19px;
    font-weight: bold;
    text-transform: uppercase;
}

.table td {
    vertical-align: middle;
}



/* New design */

html,
body {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

#root {
    position: relative;
    height: 100%;
    overflow: auto;
}

#root #main {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 130px;
    margin-left: 320px;
}

#root #header {
    position: fixed;
    z-index: 999;
    width: 130px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: transparent;
}

#root #header .mobile-categories {
    display: none;
}

#root #header .nav {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

#root #header ul {
    padding: 0;
    list-style: none;
}

#root #header .nav-item {
    min-width: 0;
    width: 100%;
    border-right: none;
}

#root #header .nav-item a {
    color: #6b6b6b;
}

#root #header .nav-item.nav-text {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
}

#root #header .nav-item.nav-text:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 70%;
    height: 1px;
    background-color: #dcdcdc;
    transform: translateX(-50%);
}

#root #header .nav-item.nav-text a {
    width: 100%;
    position: relative;
}

#root #header .nav-item:not(.nav-text) {
    position: relative;
    width: 50%;
}

#root #header .nav-item:not(.nav-text):nth-child(odd)::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    height: 70%;
    width: 1px;
    background-color: rgba(255, 255, 255, .2);
    transform: translateY(-50%);
}

#root #header .nav-item:not(.nav-text) a {
    height: 70px;
    background-color: #2778cc;
    padding: 0;
    text-align: center;
    line-height: 70px;
}

#root #header .nav-item:not(.nav-text):nth-child(3) a,
#root #header .nav-item:not(.nav-text):nth-child(4) a {
    background-color: #1f65b4;
}

#root #header li.parent {
    width: 100%;
}

#root #header li.parent.top ul {
    display: flex;
    flex-wrap: wrap;
}

#root #header li.parent.bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #eee;
}

#root #header li.parent.bottom ul {
    height: 100%;
    display: flex;
    flex-direction: column;
}

#root #header .nav-text span.nbr {
    background-color: #1c63ba;
    color: #fff;
    position: absolute;
    height: 21px;
    line-height: 21px;
    padding: 0 7px;
    top: 5px;
    right: 12px;
    font-size: 12px;
}

#root #sidebar {
    height: 100%;
    width: 320px;
    background-color: #263444;
}

#root #sidebar .mobile-toggle-buttons {
    display: none;
}

#root #sidebar .total {
    border-bottom: none;
    border-right: none;
}

#root #sidebar .total div {
    padding: 0 20px;
    background-color: transparent;
}

#root #sidebar .total div img {
    height: 22px;
}

#cart .cart-items-container {
    position: relative;
    margin-left: 15px;
    margin-right: 15px;
}

#cart .cart-items-container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    bottom: -20px;
    background: url(assets/images/arrow-down.svg) repeat-x -5px -5px;
    background-size: auto 15px;
}

#cart .cart-items {
    background-color: #f7f7f7;
    padding: 4px 8px 20px;
    height: calc(100vh - 160px);
    position: relative;
}

#cart .cart-items .separateur {
    border: none;
    border-top: 1px dashed #ddd;
    margin: 18px 12px;
}

#cart .cart-items .showprint {
    /*border-top: 3px solid #263444;*/
    margin-top: 25px;
}

#cart .cart-items>.cart-item:first-child .separateur {
    display: none;
}

#cart .cart-items .showprint .separateur {
    border-top: 2px solid #eee;
    border-color: transparent;
    margin-bottom: 25px;
}

#cart .cart-items>.cart-item .cart-content {
    margin: 20px 10px;
}

#cart .cart-item .cart-content {
    border-left: none;
    min-height: 0;
}

#cart .cart-item .cart-title {
    font-size: 13px;
    color: #555;
    display: flex;
    margin-bottom: 5px;
    padding-left: 0;
}

#cart .cart-item .cart-title span.qte {
    color: #999;
    white-space: nowrap;
    margin-right: 7px;
}

#cart .cart-item .cart-title span.title {
    flex: 1;
}

#cart .cart-item .cart-title span.price {
    color: #1c63ba;
}

#cart .cart-item .sub-items {
    color: #888;
    font-size: 12px;
    padding-left: 0;
}

#cart .cart-item .sub-items li {
    font-weight: 500;
}

#root #sidebarr .last-prod-actions {
    display: none;
}

#root #sidebarr .actions {
    background-color: #1f65b4;
    padding: 0;
}

#root #sidebarr .actions li:nth-child(4),
#root #sidebarr .actions li:nth-child(5),
#root #sidebarr .actions li:nth-child(6) {
    border-top: 1px solid rgba(255, 255, 255, .1);
}

#root #sidebarr .actions li .action-link {
    color: #fff;
    border-color: rgba(255, 255, 255, .1);
    background-color: transparent;
}

#cart .btn-payment {
    background-color: #2476d3;
    color: #fff;
}

#cart .btn-payment .price,
#cart .btn-payment .left,
#cart .btn-payment .right {
    background-color: #1f65b4;
}

/*
#27cc8f
*/

#payment {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#payment .payment-inner {
    display: table;
    margin-bottom: 50px;
}

#payment .payment-input-price .input-group {
    height: 60px;
    flex-wrap: nowrap;
}

#payment .payment-input-price .btn {
    background-color: #2476d3;
}

#payment .payment-input-price .btn.bg-dark {
    width: 100px;
    min-width: 0;
}

#payment .payment-input-price .btn img {
    height: 20px;
}

#payment .payment-input-price .btn small {
    display: block;
    font-size: 10px !important;
    text-align: center;
    font-weight: 600;
}

#payment .payment-input-price input:last-of-type:not(:first-child) {
    border-left: 2px solid #ddd;
}

#payment .price-total {
    background-color: transparent;
    color: #263444;
    font-size: 35px;
}

#payment .list-btns {
    display: flex;
}

#payment .list-btns .btn-app {
    flex: 1;
}

#payment .list-btns .btn-app.disabled .img-container {
    color: #fff;
}

#payment .list-btns.payment-methods .btn-app {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
}

#payment .list-btns.payment-methods .btn-app .img-container {
    flex: 1;
    margin-bottom: 0;
}

#payment .list-btns.payment-methods .btn-app .img-container img {
    height: 38px;
}

#payment .list-btns .btn-app .img-container .title {
    margin-top: 8px;
}

#payment .list-btns.payment-methods .btn-app .item-price {
    width: 100px;
    height: 34px;
    margin: auto;
    border-radius: 50px 50px 0 0;
    padding: 0;
}

#root .form-control {
    background-color: #eee;
    border: none;
}

#root .btn-payment {
    background-color: #2074da;
    color: #fff;
    padding: 6px 15px;
}

#root .btn-payment .right {
    background-color: #1c63ba;
}

#root .closeBtn {
    width: 60px;
    height: 60px;
    left: 0;
    right: auto;
    background-color: #1b72e2;
    border-radius: 0 0 50% 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

#root .closeBtn img {
    height: 14px;
}

#root .pop-fieldset legend {
    border-left: 4px solid #9f1c24;
}

/* Popup */
#root .pop-modal .pop-details {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#root .pop-modal .pop-body {
    max-width: 650px;
    width: 100%;
}

#root .pop-modal.pop-customisation {
    padding-top: 100px;
}

#root .pop-modal.pop-customisation .pop-details {
    display: block;
}

#root .pop-modal.pop-customisation .pop-body {
    max-width: none;
}

#root .pop-modal.pop-customisation .pop-fieldset {
    text-align: center;
    margin-top: 20px;
}

#root .pop-modal.pop-customisation .pop-fieldset legend {
    font-size: 16px;
    border-left: none;
}

#root .pop-modal.pop-customisation .top-btns .btn-app {
    background-color: #263444;
    opacity: 1;
}

#root .pop-modal.pop-customisation .top-btns .btn-app.disabled {
    background-color: #2476d3;
}

#root .pop-modal.pop-customisation .top-btns .btn-app.is-ok {
    background-image: url(assets/images/checked.svg);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center 10px;
}

#root .pop-modal.pop-customisation .btn-app {
    height: 100px;
    opacity: .7;
}

#root .pop-modal.pop-customisation .btn-app .img-container {
    color: rgba(255, 255, 255, .9);
}

#root .pop-modal.pop-customisation .top-btns .btn-app .img-container {
    height: auto !important;
    line-height: 100%;
    position: relative;
    top: 28px;
}

#root .pop-modal.pop-customisation .btn-app.active {
    /*background-color: #1f65b4;*/
    opacity: 1;
}

#root .pop-modal.pop-customisation .btn-app .item-price {
    height: 40px;
}

/* Keyboard */
#root .closeKeyboard .btn {
    background-color: #2476d3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 10px;
}

#root .closeKeyboard .btn img {
    height: 14px;
}

#root .closeKeyboard .btn span {
    font-size: 12px;
}

#root .list-btns .btn-app.hx2 {
    box-shadow: none;
}

#root .list-btns .btn-app-1 .btn {
    background-color: #263444;
    font-weight: 700;
}

#root #commandEtat {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}

#root #commandEtatContent {
    flex: 1;
    display: flex;
    flex-direction: column;
}

#root #commandEtatContent h4 {
    margin: 20px 0;
}

#root #commandEtat .btn-payment {
    background-color: transparent;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(255, 255, 255, .1);
    margin: 0;
    font-size: 16px;
}

#root #commandEtat .btn-payment.disabled {
    background-color: #2476d3;
    opacity: 1;
}

#root #list-comm .btn-app {
    margin-right: 0;
}

#root #list-comm .btn-app .btn-title {
    font-size: 22px;
}

#root #list-comm .btn-app .img-container {
    font-size: 13px;
}

#root #keyboad {
    display: flex;
    flex-direction: column;
}

#root #keyboad .keyboad-keys {
    flex: 1;
}

#root #keyboad .keyboad-keys .btn {
    height: calc((100vh - 80px - (8px * 9)) * .1);
    width: calc(25% - 8px);
    border-bottom: 3px solid #4d5b61;
    margin: 0px 4px 8px 4px;
    background: #3e484e;
    color: #ddd;
}

#root #keyboad .keyboad-keys .btn-lg-4 {
    width: calc(100% - 8px);
    margin: 0px 0 10px;
    background-color: transparent;
}

#root #keyboad .keyboad-keys .btn-lg-2 {
    width: calc(50% - 8px);
}

#root #keyboad .keyboad-keys .btn span {
    font-size: 13px;
}

#payment .w4btns {
    width: 100%;
    max-width: 650px;
}

#root #cart .clear-cart {
    display: none;
}


@media (max-width: 1100px) {
    .list-btns-filters {
        display: flex;
        width: 100%;
    }

    .list-btns-filters .float-left:first-child {
        display: flex;
        width: 220px;
    }

    .list-btns-filters .btn-app-items {
        margin-left: 30px;
        display: flex;
    }

    .list-btns-filters .btn-app-auto {
        height: 60px;
    }

    #root #sidebar {
        z-index: 999;
        transform: translateX(-100%);
    }

    #root #sidebar.is-visible {
        z-index: 9999;
        transform: none;
    }

    #root #sidebar:not(.is-visible)+#main {
        margin-left: 0;
    }

    #root #header {
        padding-top: 60px;
    }

    .mobile-toggle-buttons {
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: all;
        width: 100%;
    }

    .mobile-toggle-buttons>* {
        display: none;
    }

    .mobile-toggle-buttons .cart-toggle span {
        display: none;
    }
}

@media (min-width: 769px) and (max-width: 1100px) {
    .mobile-toggle-buttons .cart-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        background-color: #1f65b4;
    }

    .mobile-toggle-buttons .cart-toggle span {
        display: table;
        font-size: 16px;
        font-weight: 600;
        margin-right: 8px;
        margin-top: 3px;
    }
}

@media (max-width: 768px) {
    #content {
        padding: 80px 10px;
    }

    #content::after {
        content: "";
        position: fixed;
        z-index: 99;
        width: 100%;
        height: 60px;
        top: 0;
        left: 0;
        background: #263444;
        box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.3);
    }

    #root #main {
        margin-right: 0;
        margin-left: 0;
        height: auto;
        min-height: 100vh;
        overflow: hidden;
    }

    #root #sidebar {
        width: 100%;
    }

    #root #sidebar.is-visible .total {
        position: static;
        transform: none;
        padding-left: 0;
        text-align: center;
        font-size: 23px;
    }

    #root #sidebar .total {
        position: fixed;
        width: 100%;
        height: 65px;
        right: 0;
        transform: translateX(100%);
        pointer-events: none;
        font-size: 23px;
        color: #eee;
        text-align: left;
        padding-left: 80px;
    }

    #root #sidebar.is-visible .mobile-toggle-buttons {
        display: block;
    }

    #root #sidebar .total div {
        display: none;
    }

    #root #cart .cart-items-container {
        position: fixed;
        width: calc(100% - 30px);
        height: calc(100% - 95px);
    }

    #root #cart .cart-items {
        overflow: auto;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    #root #cart .btn-payment {
        left: 50px;
        top: 0;
        bottom: auto;
        width: 70px;
        height: 55px;
        border-radius: 0 0 20px 0;
        color: transparent;
        overflow: hidden;
        background-image: url(./assets/images/terminal.svg);
        background-repeat: no-repeat;
        background-size: 42px 41px;
        background-position: 13px 7px;
        background-color: transparent;
    }

    #root #cart .btn-payment .price {
        display: none;
        background-color: transparent;
        height: 40px;
        padding: 0;
        min-width: 0;
        margin-top: -3px;
        padding-right: 26px;
    }

    #root #cart .btn-payment .price img {
        height: 19px;
    }

    #root #cart .clear-cart {
        width: 50px;
        height: 55px;
        background-color: #d62a52;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 0 20px 0;
        cursor: pointer;
    }

    #root #header {
        padding-top: 0;
        pointer-events: none;
    }

    #root #header>div:first-child {
        position: relative;
        z-index: 1;
    }

    #root #header .nav {
        display: block;
    }

    #root #header li.parent.top {
        position: fixed;
        bottom: 0;
        left: 0;
        pointer-events: all;
        box-shadow: 0 -10px 20px -10px rgba(0, 0, 0, .3);
    }

    #root #header .nav-item.nav-text a {
        font-size: 12px;
        line-height: 18px;
    }

    #root #header .nav-text span.nbr {
        top: -20px;
        right: 14px;
    }

    #root #header .nav-item:not(.nav-text) {
        width: 16.66%;
        width: 25%;
    }

    #root #header .nav-item:not(.nav-text) a {
        background-color: #263444 !important;
        background-color: #2166ae !important;
        height: 55px;
        line-height: 50px;
    }

    #root #header .nav-item:not(.nav-text) img {
        transform: scale(.92);
        filter: drop-shadow(0 0 0 #fff);
    }

    #root #header .nav-item:not(.nav-text):not(:last-child)::before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        height: 70%;
        width: 1px;
        background-color: rgba(255, 255, 255, .15);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    #root #header li.parent.bottom {
        position: fixed;
        height: 100%;
        width: 90px;
        top: 0;
        right: 0;
        z-index: 99999;
        padding-top: 60px;
        display: none;
    }

    #root #header .mobile-categories {
        position: fixed;
        z-index: 999999;
        width: calc(100% - 130px);
        height: 100%;
        top: 0;
        left: 0;
        padding-top: 60px;
        background-color: #f7f7f7;
        padding: 12px 10px;
        overflow: auto;
    }

    #root #header.is-visible .mobile-categories,
    #root #header.is-visible li.parent.bottom {
        display: block;
        pointer-events: all;
    }

    #root #header .mobile-categories .btn-app-auto {
        display: none;
    }

    #root #header .mobile-categories .btn-app {
        width: calc(20% - 10px);
    }

    .list-btns::after {
        display: block;
        clear: both;
        content: "";
    }

    .list-btns .btn-app,
    .list-btns .btn-app-1 {
        width: calc(16.66% - 10px);
        margin: 5px;
        height: 100px;
        float: left;
    }

    /*{
        font-size: 10px;
        line-height: 13px;
    }*/

    .list-btns .btn-app .img-container img {
        height: 38px;
    }

    .list-btns .btn-app .img-container,
    .list-btns .btn-app .btn-title {
        font-size: 12px;
        line-height: 14px;
    }

    .list-btns .btn-app .item-price {
        font-size: 14px;
        height: 40px;
    }

    .list-btns-filters {
        overflow: auto;
    }

    .list-btns-filters .float-left:first-child {
        width: auto;
    }

    .list-btns-filters .float-left:first-child .btn-app-auto.btn-bordered {
        display: none;
    }

    .list-btns-filters::-webkit-scrollbar {
        display: none;
    }

    .list-btns-filters .btn-app-items {
        margin-left: 0;
        width: auto !important;
    }

    .list-btns-filters .btn-app-auto.btn-bordered {
        width: 80px;
        height: 40px;
        box-shadow: none;
    }

    .list-btns-filters .btn-app-items .btn-title {
        font-size: 11px;
    }

    .list-btns .btn-app-auto:not(.btn-bordered) {
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        margin: 0;
    }

    .list-btns .btn-app-auto:not(.btn-bordered)::before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        height: 70%;
        width: 1px;
        background-color: rgba(255, 255, 255, .2);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .list-btns .btn-app-auto:not(.btn-bordered) .img-container img {
        height: 23px;
        filter: invert(100%) brightness(120%);
    }

    #root #list-comm .btn-app-1 {
        width: calc(100% - 10px);
        display: flex;
    }

    #root #list-comm .btn-app {
        flex: auto;
        width: auto;
        height: auto;
        padding-top: 0;
    }

    #root #list-comm .btn-app-1 .img-container {
        font-size: 12px;
        line-height: 18px;
        height: auto;
        display: table;
        margin-bottom: 0;
        text-align: left;
    }

    #root #list-comm .btn-app-1 .btn-title {
        font-size: 15px;
        display: table;
        padding-left: 5px;
    }

    #root #list-comm .btn-app-1 .btn {
        flex: auto;
        width: 110px;
        height: auto;
    }

    #root #sidebar .mobile-toggle-buttons {
        display: table;
    }

    .mobile-toggle-buttons {
        position: fixed;
        width: auto;
        pointer-events: all;
    }

    .mobile-toggle-buttons.menu-toggle {
        display: none;
        z-index: 99;
    }

    #root #header.is-visible .mobile-toggle-buttons.menu-toggle {
        display: block;
    }

    .mobile-toggle-buttons>* {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        float: left;
    }

    .mobile-toggle-buttons>*:not(:last-child)::before {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        height: 70%;
        width: 1px;
        background-color: rgba(255, 255, 255, .1);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    #root .pop-modal.pop-customisation {
        padding-top: 80px;
        height: auto;
        min-height: 100%;
    }

    #root .pop-modal.pop-customisation .top-btns {
        display: flex;
        overflow: auto;
    }

    #root .pop-modal.pop-customisation .top-btns::-webkit-scrollbar {
        display: none;
    }

    #root .pop-modal.pop-customisation .btn-app {
        height: 80px;
    }

    #root .pop-modal.pop-customisation .top-btns .btn-app {
        flex: 1 0 auto;
        width: 110px;
        height: 60px;
    }

    #root .pop-modal.pop-customisation .top-btns .btn-app .img-container {
        top: 24px;
    }

    #root #sidebarr .last-prod-actions.is-visible.can-show {
        display: block;
    }

    #root #sidebarr .last-prod-actions {
        position: fixed;
        top: 0;
        width: 100%;
        transform: translate(100%, 60px);
        box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.3);
        background-color: #263444;
    }

    #root #sidebarr .last-prod-actions .title {
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        color: #ccc;
        padding-top: 20px;
    }

    #root #sidebarr .last-prod-actions .title strong {
        color: #fff;
        font-size: 14px;
        margin-right: 5px;
    }

    #root #payment .mb-5 {
        margin-bottom: 30px !important;
    }

    #root #payment .price-total {
        display: none;
    }

    #root #payment .list-btns {
        display: block;
    }

    #root #payment .payment-input-price .btn {
        min-width: 70px;
        width: 70px;
    }

    #root #payment .payment-input-price * {
        font-size: 15px;
        flex: 1;
    }

    #root #payment .payment-input-price input:last-of-type:not(:first-child) {
        flex: 0 1 70px !important;
    }

    #root #payment .payment-input-price .input-group {
        height: 50px;
    }

    #root #payment .payment-input-price .input-value {
        max-width: none;
        min-width: 0;
    }

    #root #payment .payment-input-price .btn img {
        height: 13px;
    }

    #root #payment .payment-input-price .btn.btn-reset img {
        height: 10px !important;
    }

    #root #payment .list-btns.payment-methods .btn-app {
        height: 105px;
    }

    #root #payment .list-btns.payment-methods .btn-app .img-container img {
        height: 32px;
    }

    #root #payment .list-btns.payment-methods .btn-app .item-price {
        width: 100%;
        height: 26px;
    }
}

@media (max-width: 767px) {
    #root #app-login .login {
        width: 100%;
        padding: 15px;
    }

    .list-btns .btn-app,
    .list-btns .btn-app-1 {
        width: calc(25% - 10px);
        height: 80px;
    }

    .list-btns .btn-app .img-container img {
        height: 35px;
    }

    .list-btns .btn-app .img-container,
    .list-btns .btn-app .btn-title {
        font-size: 10px;
        line-height: 13px;
    }

    .list-btns .btn-app .btn-title:not(.item-price) {
        padding: 0;
    }

    .list-btns .btn-app .item-price {
        font-size: 12px;
        height: 30px;
    }

    #root #header {
        width: 90px;
    }

    #root #header .mobile-categories {
        width: calc(100% - 90px);
    }

    #root #header .mobile-categories .btn-app {
        width: calc(33.33% - 10px);
    }

    #root #sidebarr .last-prod-actions .title {
        font-size: 12px;
    }
}

#root .form-control.is-invalid {
    border: 1px solid red;
}