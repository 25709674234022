#sidebar {
    position: fixed;
    height: 100vh;
    background-color: #282f32;
    color: #FFF;
    width: 350px;
    top: 0;
    left: 0;
}

#sidebar .total,
.price-total {
    text-align: center;
    height: 60px;
    line-height: 60px;
    font-size: 26px;
    font-weight: bold;
    border-bottom: 1px solid #393f42;
    border-right: 1px solid #393f42;
}

.price-total {
    border: none;
}

.sidebar-content {
    padding: 40px 30px;
}

.btn-payment {
    background-color: #9f1c24;
    width: 100%;
    border-radius: 0px;
    line-height: 55px;
    position: relative;
    border: none;
    text-align: left;
    color: #f1ddde;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 30px;
}

.btn-payment:hover {
    color: #f1ddde;
}

.btn-payment .icon {
    margin-right: 10px;
    height: 20px;
}

.btn-payment .price,
.btn-payment .left,
.btn-payment .right {
    background-color: #8a1c24;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    padding: 8px 15px;
    min-width: 90px;
    text-align: center;
}

.btn-payment .left {
    right: auto;
    left: 0;
    min-width: 40px;
}

.btn-payment.btn-valid {
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 15%;
}

.cart-items {
    height: calc(100vh - 130px);
    overflow: hidden;
}

.cart-items .separateur {
    border: 1px dotted #bbbbbb;
    margin: 15px 0;
}

.cart-item {
    cursor: pointer;
}

.cart-item .cart-title {
    font-size: 12px;
    font-weight: bold;
    color: #bbbbbb;
    padding-left: 5px;
    margin: 0;
}

.cart-item .sub-items {
    color: #6a6c6c;
    font-size: 13px;
    margin: 0;
    padding-left: 5px;
    margin: 0;
}

.cart-item .sub-items li {
    font-weight: bold;
}

.cart-item .sub-items li:first-child::before {
    content: "- ";
}

.cart-item .sub-items li::after {
    content: ",";
}

.cart-item .sub-items li:last-child::after,
.cart-item .sub-items.list-unstyled li::after,
.cart-item .sub-items.list-unstyled li::before {
    content: "";
}

.cart-item .price {
    float: right;
}

.cart-item .sub-items.list-unstyled li {
    font-weight: normal;
}

.cart-item:first-child .cart-content {
    margin-top: 20px;
}

.cart-item .cart-content {
    margin: 40px 10px 0;
    min-height: 60px;
    border-left: 5px solid #9f1c24;
}

.cart-item .cart-content.h-auto {
    min-height: auto !important;
    margin: 15px 10px;
}

#sidebarr .actions {
    background-color: #9f1c24;
    min-height: 60px;
    line-height: 60px;
    overflow: hidden;
    padding: 0 15px;
    display: none;
}

#sidebarr .actions.active {
    display: block;
    ;
}

#sidebarr .actions ul {
    padding: 0;
    margin: 0;
}

#sidebarr .actions li {
    margin: 0;
    text-align: center;
    width: 33%;
}

#sidebarr .actions li .action-link {
    border-right: 1px solid #a9333a;
    padding: 0 10px;
    display: block;
    margin: 5px 0;
    color: #e2bbbe;
    font-size: 24px;
}

#sidebarr .actions li:nth-child(3n+0) .action-link {
    border: none;
}

#sidebarr .actions li.delete .action-link {
    background-color: #8a1c24;
}

#sidebarr .actions li img {
    height: 25px;
}

#sidebarr .actions li.delete img {
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Keyboard */
#keyboad {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #282f32;
    padding: 10px;
    display: nonee;
}

.keyboad-keys {
    position: relative;
    text-align: center;
}

#keyboad .btn {
    width: 65px;
    height: 60px;
    border-radius: 0px;
    margin: 0 5px 5px 0;
    font-weight: bold;
    font-size: 20px;
}

#keyboad .btn-lg-2 {
    width: 135px;
}

#keyboad .btn-lg-4 {
    width: 275px;
    height: 50px;
}

#keyboad .btn span {
    font-size: 10px;
    text-transform: none;
}

.closeKeyboard {
    text-align: center;
}

.closeKeyboard .btn {
    background-color: #9f1c24;
    color: #e7b2b4;
    border-radius: 50% !important;
    width: 50px !important;
    height: 50px !important;
}

.total div {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    padding: 0 10px;
    background: #393f42;
    /* display: none; */
}

.total div.arrow-bottom {
    right: 0;
    left: auto;
}

.total div img {
    height: 30px;
}

.total .arrow-top img {
    transform: rotate(90deg);
}

.total .arrow-bottom img {
    transform: rotate(-90deg);
}

/* @page {
    size: 3.5in 100vh;
    margin: 10mm 8mm;
} */

@media print {
    * {
        color: #555 !important;
        transform: none !important;
        font-weight: normal !important;
        font-size: 70%;
    }

    body {
        position: static;
        width: 100%;
    }

    #main,
    #cart .total,
    #btnValidateCommande,
    #root #sidebarr .actions,
    #root #sidebar .mobile-toggle-buttons,
    #root #cart .clear-cart,
    #root #cart .cart-items>.cart-item .separateur {
        display: none !important;
    }

    .logo-restaurant {
        display: block !important;
        font-weight: 600;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    #root .barcode {
        display: block !important;
        height: 60px;
        margin: auto;
        margin-top: 20px;
    }

    #root #sidebar {
        width: 100%;
    }

    #root #cart .cart-items .showprint {
        margin-top: 0;
    }

    #root #cart .cart-items-container,
    #root #cart .cart-items .cart-item .cart-content,
    #root #cart .cart-items .separateur {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    #root #cart .cart-items .cart-item .cart-content {
        margin: 10px;
    }

    #root #cart .cart-items .separateur {
        border-top-color: #aaa;
    }

    #root #cart .cart-items .showprint .separateur {
        display: none;
    }

    #root #cart .cart-items {
        width: 100%;
        height: auto;
        padding: 0;
        overflow: visible;
    }

    #root #cart .cart-item .sub-items {
        font-size: 10px;
        font-weight: normal;
    }

    #root #sidebar .stars {
        display: block !important;
    }
}