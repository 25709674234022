.payment-input-price * {
    border-radius: 0px;
    font-size: 20px;
    font-weight: bold;
}
.payment-input-price .input-group-text {
    font-size: 15px;
    border-color: #ba1c24;
}
.payment-input-price .input-group {
    height: 50px;
}
.payment-input-price .form-control {
    height: auto;
    text-align: center;
}
.payment-input-price .btn {
    background-color:#ba1c24;
    color:  #ffffff;
    min-width: 60px;
}

.price-total {
    border: none;
    background: #e7b2b4;
    color: #ba1c24;
}

.cols .payment-input-price {
    margin: 0;
    width: 100%;
}

.payment-input-price .percent {
    min-width: 80px;
    cursor: pointer;
    justify-content: center;
    background: #e7b2b4;
    color: #FFF;
}

.w4btns {
    width: 630px;
    margin: auto;
}
#payment .btn {
    min-width: 110px;
}
.sm-btn {
    min-width: 50px !important;
}
.payment-input-price .input-value {
    max-width: 150px;
    min-width: 150px;
}